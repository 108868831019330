import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import CookieConsent from "./Components/CookieConsent";

import Home from "./Pages/Home";
import VIP from "./Pages/Vip";
import Servers from "./Pages/Servers";
import NotFound from "./Pages/NotFound";
import BanPolicy from "./Pages/BanPolicy";
import AdminRecruitment from "./Pages/AdminRecruitment";
import InGameAdminGuide from "./Pages/InGameAdminGuide";
import DiscordAdminGuide from "./Pages/DiscordAdminGuide";

import './styles.css';

function App() {
    return (
        <div>
            <Navbar />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/servers" element={<Servers />} />
                    <Route path="/vip" element={<VIP />} />
                    <Route path="/recruitment" element={<AdminRecruitment />} />
                    <Route path="/ingame-admin-guide" element={<InGameAdminGuide />} />
                    <Route path="/discord-admin-guide" element={<DiscordAdminGuide />} />
                    <Route path="/banpolicy" element={<BanPolicy />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
            <CookieConsent />
            <Footer />
        </div>
    );
}

export default App;
